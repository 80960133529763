<div class="merge-popup">
    <button id="merge_succeeded_snack" class="snack_button" at-raised-button color="primary"
            (click)="snackbar_SucceededPost()">
        Snackbar CSS Styles
    </button>
    <button id="merge_failed_snack" class="snack_button" at-raised-button color="primary"
            (click)="snackbar_FailedPost()">
        Snackbar CSS Styles
    </button>
    <button id="merge_btn" class="snack_button" at-raised-button color="primary" (click)="setMergedConnection()">
        Snackbar CSS Styles
    </button>
    <button id="addToMainDatabase" class="snack_button" at-raised-button color="primary"
            (click)="postAddToMainDataBase()">
        Snackbar CSS Styles
    </button>
    <div class="p">
        <p *ngIf="companiesToMerge_length>1 || peopleToMerge_length>1">Construct
            the {{selectedDataType.toLocaleLowerCase()}} that will be sent to the main database</p>
        <div *ngIf="selectedDataType === 'Company'">
            <p *ngIf="companiesToMerge_length<=1">Please choose at least 2 records that have to merge!</p>
            <button *ngIf="companiesToMerge_length<=1" class="cancel-button" mat-dialog-close>Cancel</button>
        </div>
        <div *ngIf="selectedDataType === 'Person'">
            <p *ngIf="peopleToMerge_length<=1">Please choose at least 2 records to merge!</p>
            <button *ngIf="peopleToMerge_length<=1" class="cancel-button" mat-dialog-close>Cancel</button>
        </div>
    </div>
    <div *ngIf="companiesToMerge_length>1">
        <div *ngIf="selectedDataType==='Company'">
            <div class="table-div">
                <table class="merge-table" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="merge-th">Winpure PrimeK</td>
                        <td class="merge-th">Company Id</td>
                        <td class="merge-th">Company Name</td>
                        <td class="merge-th">Address1</td>
                        <td class="merge-th">Address2</td>
                        <td class="merge-th">City</td>
                        <td class="merge-th">State</td>
                        <td class="merge-th">Zip</td>
                        <td class="merge-th">Country</td>
                        <td class="merge-th">Phone</td>
                        <td class="merge-th">Website</td>
                        <td class="merge-th">Ticker</td>
                        <td class="merge-th">CIK ID</td>
                        <td class="merge-th">SIC Code</td>
                        <td class="merge-th">Exchange</td>
                        <td class="merge-th">Annual Revenue</td>
                        <td class="merge-th">Employee Exact</td>
                        <td class="merge-th">Industry Category</td>
                        <td class="merge-th">Ownership Type</td>
                        <td class="merge-th">Ownership Sub Type</td>
                        <td class="merge-th">IPO Date</td>
                        <td class="merge-th">Verified Date</td>
                        <td class="merge-th">How Verified?</td>
                        <td class="merge-th">Active Status</td>
                        <td class="merge-th">Address Source</td>
                    </tr>
                    <tr [formGroup]="companyControl">
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-winpureprimek"
                                        (selectionChange)="constructMergedCompany('WinpurePrimeK', $event)"
                                        [(ngModel)]="finalCompaniesToMerge[0].winPurePrimK" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let i of companiesToMerge"
                                            [value]="i.winPurePrimK">{{i.winPurePrimK}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-companyid"
                                        (selectionChange)="constructMergedCompany('CompanyId', $event)"
                                        [(ngModel)]="finalCompaniesToMerge[0].companyId" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let i of companiesToMerge"
                                            [value]="i.companyId">{{i.companyId}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-companyName"
                                        (selectionChange)="constructMergedCompany('CompanyName', $event)"
                                        formControlName="companyName" name="companyName">
                                <mat-option *ngFor="let companyName of companySelection.companyName"
                                            [value]="companyName">
                                    {{companyName}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-address1"
                                        (selectionChange)="constructMergedCompany('Address1', $event)"
                                        formControlName="address1" name="address1">
                                <mat-option *ngFor="let addr of companySelection.address1" [value]="addr">
                                    {{addr}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-address2"
                                        (selectionChange)="constructMergedCompany('Address2', $event)"
                                        formControlName="address2" name="address2">
                                <mat-option *ngFor="let addr of companySelection.address2" [value]="addr">
                                    {{addr}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-city"
                                        (selectionChange)="constructMergedCompany('City', $event)"
                                        formControlName="city" name="city">
                                <mat-option *ngFor="let city of companySelection.city" [value]="city">
                                    {{city}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-state"
                                        (selectionChange)="constructMergedCompany('State', $event)" formControlName="state" name="state">
                                <mat-option *ngFor="let state of companySelection.state" [value]="state.stateCode">
                                    {{state.stateCode}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-zip"
                                        (selectionChange)="constructMergedCompany('Zip', $event)" formControlName="zip" name="zip">
                                <mat-option *ngFor="let zip of companySelection.zip" [value]="zip">
                                    {{zip}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-country"
                                        (selectionChange)="constructMergedCompany('Country', $event)" formControlName="country" name="country">
                                <mat-option *ngFor="let country of companySelection.country" [value]="country">
                                    {{country}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-phone"
                                        (selectionChange)="constructMergedCompany('Phone', $event)"
                                        formControlName="phone" name="phone">
                                <mat-option *ngFor="let phone of companySelection.phone" [value]="phone">
                                    {{phone}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-website"
                                        (selectionChange)="constructMergedCompany('Website', $event)"
                                        formControlName="website" name="website">
                                <mat-option *ngFor="let website of companySelection.website" [value]="website">
                                    {{website}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-ticker"
                                        (selectionChange)="constructMergedCompany('Ticker Company', $event)" formControlName="ticker" name="ticker">
                                <mat-option *ngFor="let ticker of companySelection.ticker" [value]="ticker">
                                    {{ticker}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-cikId"
                                        (selectionChange)="constructMergedCompany('CIK ID', $event)" formControlName="cikId" name="ticker">
                                <mat-option *ngFor="let cikId of companySelection.cikId" [value]="cikId">
                                    {{cikId}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-sicCode"
                                        (selectionChange)="constructMergedCompany('SIC Code', $event)" formControlName="sicCode" name="ticker">
                                <mat-option *ngFor="let sicCode of companySelection.sicCode" [value]="sicCode">
                                    {{sicCode}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-exchange"
                                        (selectionChange)="constructMergedCompany('Exchange', $event)" formControlName="exchange" name="ticker">
                                <mat-option *ngFor="let exchange of companySelection.exchange" [value]="exchange">
                                    {{exchange}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-annualRevenue"
                                        (selectionChange)="constructMergedCompany('AnnualRevenue', $event)"formControlName="annualRevenue" name="annualRevenue">
                                <mat-option *ngFor="let annualRevenue of companySelection.annualRevenue"
                                            [value]="annualRevenue">
                                    {{annualRevenue}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-numberOfEmployees"
                                        (selectionChange)="constructMergedCompany('Employee Exact', $event)" formControlName="numberOfEmployees" name="employeeExact">
                                <mat-option *ngFor="let numberOfEmployees of companySelection.numberOfEmployees"
                                            [value]="numberOfEmployees">
                                    {{numberOfEmployees}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-businessIndustry"
                                        (selectionChange)="constructMergedCompany('Industry', $event)" formControlName="businessIndustry" name="industry">
                                <mat-option *ngFor="let businessIndustry of companySelection.businessIndustry"
                                            [value]="businessIndustry">
                                    {{businessIndustry}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-ownershipTypeDesc"
                                        (selectionChange)="constructMergedCompany('Ownership', $event)"
                                        formControlName="ownershipTypeDesc" name="ownership">
                                <mat-option *ngFor="let ownership of companySelection.ownershipTypeDesc"
                                            [value]="ownership">
                                    {{ownership}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-ownershipSubType"
                                        (selectionChange)="constructMergedCompany('Ownership Sub Type', $event)"
                                        formControlName="ownershipSubType" name="ownershipSubType">
                                <mat-option *ngFor="let ownership of companySelection.ownershipSubType"
                                            [value]="ownership">
                                    {{ownership}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-ipoDate"
                                        (selectionChange)="constructMergedCompany('IPO Date', $event)"
                                        formControlName="ipoDate" name="ipoDate">
                                <mat-option *ngFor="let date of companySelection.ipoDate"
                                            [value]="date">
                                    {{date}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-verifiedDate"
                                        (selectionChange)="constructMergedCompany('VerifiedDate', $event)"
                                        formControlName="verifiedDate" name="verifiedDate">
                                <mat-option *ngFor="let verifiedDate of companySelection.verifiedDate"
                                            [value]="verifiedDate.value">
                                    {{verifiedDate.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-howVerified"
                                        (selectionChange)="constructMergedCompany('HowVerified', $event)"
                                        formControlName="howVerified" name="verifiedDate">
                                <mat-option *ngFor="let howVerified of companySelection.howVerified"
                                            [value]="howVerified">
                                    {{howVerified}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-activeStatus"
                                        (selectionChange)="constructMergedCompany('ActiveStatus', $event)"
                                        formControlName="activeStatus" name="activeStatus">
                                <mat-option *ngFor="let activeStatus of companySelection.activeStatus"
                                            [value]="activeStatus">
                                    {{activeStatus}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="company-select-addressSource"
                                        (selectionChange)="constructMergedCompany('AddressSource', $event)"
                                        formControlName="addressSource" name="addressSource">
                                <mat-option *ngFor="let addressSource of companySelection.addressSource"
                                            [value]="addressSource">
                                    {{addressSource}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="peopleToMerge_length>1">
        <div *ngIf="selectedDataType=='Person'">
            <div class="table-div">
                <table class="merge-table" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="merge-th" style="display: none">Winpure PrimeK</td>
                        <td class="merge-th">PersonId</td>
                        <td class="merge-th">First Name</td>
                        <td class="merge-th">Middle Name</td>
                        <td class="merge-th">Last Name</td>
                        <td class="merge-th">Title</td>
                        <td class="merge-th">Email</td>
                        <td class="merge-th">AltEmail</td>
                        <td class="merge-th">Direct Phone</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">WP ID</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Member Clicks ID</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">BM WP ID</td>
                        <td class="merge-th">Shipping Add1</td>
                        <td class="merge-th">Shipping Add2</td>
                        <td class="merge-th">Shipping City</td>
                        <td class="merge-th">Shipping State</td>
                        <td class="merge-th">Shipping Zipcode</td>
                        <td class="merge-th">Shipping Country</td>
                        <td class="merge-th">Alternate Address Source</td>
                        <td class="merge-th">Alternate Address Preferred</td>
                        <td class="merge-th">Alternate Address Type</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">CEX Sub Status</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">CBM Sub Status</td>
                        <td class="merge-th">CEO Digital & Print</td>
                        <td class="merge-th">CEO Digital Only</td>
                        <td class="merge-th">CBM Digital & Print</td>
                        <td class="merge-th">CBM Digital Only</td>
                        <td class="merge-th extend-th">CEN</td>
                        <td class="merge-th extend-th">SEN</td>
                        <td class="merge-th">CEO100</td>
                        <td class="merge-th">CFO100</td>
                        <td class="merge-th extend-th">NLL</td>
                        <td class="merge-th extend-th">CEBI</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">CBM Network</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Director Fundamentals</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">ACCD</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Annual Revenue BPA</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Ownership Type BPA</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Ownership Sub Type</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Number of Employees BPA</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Business/ Industry</td>
                        <td class="merge-th" *ngIf="isPersonApiData == true">Other Industry</td>
                        <td class="merge-th extend-th" >BPA Qual Date</td>
                        <td class="merge-th extend-th" >BPA Qual Source</td>
                        <td class="merge-th extend-th" >BPA Job Title</td>
                        <td class="merge-th">Verified Date</td>
                        <td class="merge-th">Active Status</td>
                        <td class="merge-th">Primary Co ID</td>
                    </tr>
                    <tr>
                        <td class="merge-td" style="display: none">
                            <mat-select id="person-select-winpureprimk" class="merge-select"
                                        (selectionChange)="constructMergedPerson('WinpurePrimeK', $event)"
                                        [(ngModel)]="finalPeopleToMerge[0].winPurePrimK">
                                <mat-option *ngFor="let i of peopleToMerge"
                                            [value]="i.winPurePrimK">{{i.winPurePrimK}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select"
                                        (selectionChange)="constructMergedPerson('PersonId', $event)"
                                        [(ngModel)]="finalPeopleToMerge[0].personId">
                                <mat-option *ngFor="let i of peopleToMerge"
                                            [value]="i.personId">{{i.personId}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-firstName"
                                        (selectionChange)="constructMergedPerson('FirstName', $event)"
                                        [formControl]="firstNameControl" name="firstName">
                                <mat-option *ngFor="let firstName of selectPerson_firstName" [value]="firstName.value">
                                    {{firstName.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-middleName"
                                        (selectionChange)="constructMergedPerson('MiddleName', $event)"
                                        [formControl]="middleNameControl" name="middleName">
                                <mat-option *ngFor="let middleName of selectPerson_middleName"
                                            [value]="middleName.value">
                                    {{middleName.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-lastName"
                                        (selectionChange)="constructMergedPerson('LastName', $event)"
                                        [formControl]="lastNameControl" name="lastName">
                                <mat-option *ngFor="let lastName of selectPerson_lastName" [value]="lastName.value">
                                    {{lastName.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-title"
                                        (selectionChange)="constructMergedPerson('Title', $event)"
                                        [formControl]="titleControl" name="title">
                                <mat-option *ngFor="let title of selectPerson_title" [value]="title.value">
                                    {{title.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-email"
                                        (selectionChange)="constructMergedPerson('Email', $event)"
                                        [formControl]="emailControl" name="email">
                                <mat-option *ngFor="let email of selectPerson_email" [value]="email.value">
                                    {{email.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-altEmail"
                                        (selectionChange)="constructMergedPerson('AltEmail', $event)"
                                        [formControl]="altEmailControl" name="altEmail">
                                <mat-option *ngFor="let altEmail of selectPerson_altEmail" [value]="altEmail.value">
                                    {{altEmail.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-directPhone"
                                        (selectionChange)="constructMergedPerson('DirectPhone', $event)"
                                        [formControl]="directPhoneControl" name="directPhone">
                                <mat-option *ngFor="let directPhone of selectPerson_directPhone"
                                            [value]="directPhone.value">
                                    {{directPhone.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData==true">
                            <mat-select class="merge-select" id="person-select-wordpressId"
                                        (selectionChange)="constructMergedPerson('WordpressId', $event)"
                                        [formControl]="personWordpressIdControl">
                                <mat-option *ngFor="let id of selectPerson_WordpressId"
                                            [value]="id.value">{{id.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData==true">
                            <mat-select class="merge-select" id="person-select-memberClicksId"
                                        (selectionChange)="constructMergedPerson('MemberClicksId', $event)"
                                        [formControl]="personMemberClicksIdControl">
                                <mat-option *ngFor="let id of selectPerson_memberClicksId"
                                            [value]="id.value">{{id.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData==true">
                            <mat-select class="merge-select" id="person-select-boardMembersId"
                                        (selectionChange)="constructMergedPerson('BoardMembersId', $event)"
                                        [formControl]="personBoardMembersIdControl">
                                <mat-option *ngFor="let id of selectPersonBoardMembersId"
                                            [value]="id.value">{{id.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-primaryAddress1"
                                        (selectionChange)="constructMergedPerson('PrimaryAddress1', $event)"
                                        [formControl]="primaryAddress1Control">
                                <mat-option *ngFor="let address1 of selectPerson_primaryAddress1"
                                            [value]="address1.value">{{address1.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-primaryAddress2"
                                        (selectionChange)="constructMergedPerson('PrimaryAddress2', $event)"
                                        [formControl]="primaryAddress2Control">
                                <mat-option *ngFor="let address2 of selectPerson_primaryAddress2"
                                            [value]="address2.value">{{address2.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-primaryCity"
                                        (selectionChange)="constructMergedPerson('PrimaryCity', $event)"
                                        [formControl]="primaryCityControl">
                                <mat-option *ngFor="let city of selectPerson_primaryCity"
                                            [value]="city.value">{{city.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-primaryState"
                                        (selectionChange)="constructMergedPerson('PrimaryState', $event)"
                                        [formControl]="primaryStateControl">
                                <mat-option *ngFor="let state of selectPerson_primaryState"
                                            [value]="state.stateCode">{{state.stateCode}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-primaryZip"
                                        (selectionChange)="constructMergedPerson('PrimaryZip', $event)"
                                        [formControl]="primaryZipControl">
                                <mat-option *ngFor="let zip of selectPerson_primaryZip"
                                            [value]="zip.value">{{zip.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-primaryCountry"
                                        (selectionChange)="constructMergedPerson('PrimaryCountry', $event)"
                                        [formControl]="primaryCountryControl">
                                <mat-option *ngFor="let country of selectPerson_primaryCountry"
                                            [value]="country.value">{{country.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-alternateAddressSource"
                                        (selectionChange)="constructMergedPerson('AlternateAddressSource', $event)"
                                        [formControl]="alternateAddressSourceControl">
                                <mat-option *ngFor="let address of selectPerson_alternateAddressSource"
                                            [value]="address.value">{{address.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-alternateAddressPreferred"
                                        (selectionChange)="constructMergedPerson('AlternateAddressPreferred', $event)"
                                        [formControl]="alternateAddressPreferredControl">
                                <mat-option *ngFor="let address of selectPerson_alternateAddressPreferred"
                                            [value]="address.value">{{address.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-alternateAddressType"
                                        (selectionChange)="constructMergedPerson('AlternateAddressType', $event)"
                                        [formControl]="alternateAddressTypeControl">
                                <mat-option *ngFor="let address of selectPerson_alternateAddressType"
                                            [value]="address.value">{{address.value}}</mat-option>
                            </mat-select>
                        </td>

                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-CEXSubStatus"
                                        (selectionChange)="constructMergedPerson('CEXSubStatus', $event)"
                                        [formControl]="cexSubStatusControl">
                                <mat-option *ngFor="let code of selectPerson_cexSubStatus"
                                            [value]="code.value">{{code.value}}</mat-option>
                            </mat-select>
                        </td>

                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-CBMSubStatus"
                                        (selectionChange)="constructMergedPerson('CBMSubStatus', $event)"
                                        [formControl]="cbmSubStatusControl">
                                <mat-option *ngFor="let code of selectPerson_cbmSubStatus"
                                            [value]="code.value">{{code.value}}</mat-option>
                            </mat-select>
                        </td>

                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-ceoDigitalAndPrint"
                                        (selectionChange)="constructMergedPerson('CEODigitalAndPrint', $event)"
                                        [formControl]="ceoDigitalAndPrintControl">
                                <mat-option *ngFor="let ceo of selectPerson_ceoDigitalAndPrint"
                                            [value]="ceo.value">{{ceo.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-ceoDigitalOnly"
                                        (selectionChange)="constructMergedPerson('CEODigitalOnly', $event)"
                                        [formControl]="ceoDigitalOnlyControl">
                                <mat-option *ngFor="let ceo of selectPerson_ceoDigitalOnly"
                                            [value]="ceo.value">{{ceo.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-cbmDigitalAndPrint"
                                        (selectionChange)="constructMergedPerson('CBMDigitalAndPrint', $event)"
                                        [formControl]="cbmDigitalAndPrintControl">
                                <mat-option *ngFor="let cbm of selectPerson_cbmDigitalAndPrint"
                                            [value]="cbm.value">{{cbm.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-cbmDigitalOnly"
                                        (selectionChange)="constructMergedPerson('CBMDigitalOnly', $event)"
                                        [formControl]="cbmDigitalOnlyControl">
                                <mat-option *ngFor="let cbm of selectPerson_cbmDigitalOnly"
                                            [value]="cbm.value">{{cbm.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-cen"
                                        (selectionChange)="constructMergedPerson('CEN', $event)"
                                        [formControl]="cenControl">
                                <mat-option *ngFor="let cen of selectPerson_cen"
                                            [value]="cen.value">{{cen.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-sen"
                                        (selectionChange)="constructMergedPerson('SEN', $event)"
                                        [formControl]="senControl">
                                <mat-option *ngFor="let sen of selectPerson_sen"
                                            [value]="sen.value">{{sen.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-ceo100"
                                        (selectionChange)="constructMergedPerson('CEO100', $event)"
                                        [formControl]="ceo100Control">
                                <mat-option *ngFor="let ceo of selectPerson_ceo100"
                                            [value]="ceo.value">{{ceo.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-cfo100"
                                        (selectionChange)="constructMergedPerson('CFO100', $event)"
                                        [formControl]="cfo100Control">
                                <mat-option *ngFor="let cfo of selectPerson_cfo100"
                                            [value]="cfo.value">{{cfo.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-nll"
                                        (selectionChange)="constructMergedPerson('NLL', $event)"
                                        [formControl]="nllControl">
                                <mat-option *ngFor="let nll of selectPerson_nll"
                                            [value]="nll.value">{{nll.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-cebi"
                                        (selectionChange)="constructMergedPerson('CEBI', $event)"
                                        [formControl]="cebiControl">
                                <mat-option *ngFor="let cebi of selectPerson_cebi"
                                            [value]="cebi.value">{{cebi.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-cbmNetwork"
                                        (selectionChange)="constructMergedPerson('CbmNetwork', $event)"
                                        [formControl]="personCbmNetworkControl">
                                <mat-option *ngFor="let cbm of selectPersonCbmNetwork"
                                            [value]="cbm.value">{{cbm.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-directorFundamentals"
                                        (selectionChange)="constructMergedPerson('DirectorFundamentals', $event)"
                                        [formControl]="personDirectorFundamentalsControl">
                                <mat-option *ngFor="let dir of selectPersonDirectorFundamentals"
                                            [value]="dir.value">{{dir.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-accd"
                                        (selectionChange)="constructMergedPerson('ACCD', $event)"
                                        [formControl]="personACCD">
                                <mat-option *ngFor="let item of selectPersonACCD"
                                            [value]="item.value">{{item.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-revenue"
                                        (selectionChange)="constructMergedPerson('Revenue', $event)"
                                        [formControl]="revenueControl">
                                <mat-option *ngFor="let revenue of selectPerson_revenue"
                                            [value]="revenue.value">{{revenue.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-ownership"
                                        (selectionChange)="constructMergedPerson('Ownership', $event)"
                                        [formControl]="ownershipControl">
                                <mat-option *ngFor="let ownership of selectPerson_ownership"
                                            [value]="ownership.value">{{ownership.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-ownershipSubType"
                                        (selectionChange)="constructMergedPerson('OwnershipSubType', $event)"
                                        [formControl]="ownershipSubTypeControl">
                                <mat-option *ngFor="let ownership of selectPerson_ownershipSubType"
                                            [value]="ownership.value">{{ownership.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-employees"
                                        (selectionChange)="constructMergedPerson('Employees', $event)"
                                        [formControl]="employeesControl">
                                <mat-option *ngFor="let employees of selectPerson_employees"
                                            [value]="employees.value">{{employees.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-industry"
                                        (selectionChange)="constructMergedPerson('Industry', $event)"
                                        [formControl]="industryControl">
                                <mat-option *ngFor="let industry of selectPerson_industry"
                                            [value]="industry.value">{{industry.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td" *ngIf="isPersonApiData == true">
                            <mat-select class="merge-select" id="person-select-otherIndustry"
                                        (selectionChange)="constructMergedPerson('OtherIndustry', $event)"
                                        [formControl]="otherIndustryControl">
                                <mat-option *ngFor="let industry of selectPerson_otherIndustry"
                                            [value]="industry.value">{{industry.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-bpa"
                                        (selectionChange)="constructMergedPerson('BPAQualDate', $event)"
                                        [formControl]="personBPAControl">
                                <mat-option *ngFor="let bpa of selectPerson_bpaQualDate"
                                            [value]="bpa.value">{{bpa.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-bpaQualSource"
                                        (selectionChange)="constructMergedPerson('BPAQualSource', $event)"
                                        [formControl]="personBPAQualSourceControl">
                                <mat-option *ngFor="let bpa of selectPerson_bpaQualSource"
                                            [value]="bpa.value">{{bpa.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-bpaJobTitle"
                                        (selectionChange)="constructMergedPerson('BPAJobTitle', $event)"
                                        [formControl]="personBPAJobTitleControl">
                                <mat-option *ngFor="let bpa of selectPerson_bpaJobTitle"
                                            [value]="bpa.value">{{bpa.value}}</mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-verifiedDate"
                                        (selectionChange)="constructMergedPerson('VerifiedDate', $event)"
                                        [formControl]="personVerifiedDateControl" name="verifiedDate">
                                <mat-option *ngFor="let verifiedDate of selectPerson_verifiedDate"
                                            [value]="verifiedDate.value">
                                    {{verifiedDate.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-activeStatus"
                                        (selectionChange)="constructMergedPerson('ActiveStatus', $event)"
                                        [formControl]="personActiveStatusControl" name="activeStatus">
                                <mat-option *ngFor="let activeStatus of selectPerson_activeStatus"
                                            [value]="activeStatus.value">
                                    {{activeStatus.value}}
                                </mat-option>
                            </mat-select>
                        </td>
                        <td class="merge-td">
                            <mat-select class="merge-select" id="person-select-companyId"
                                        (selectionChange)="constructMergedPerson('PrimaryCompanyId', $event)"
                                        [formControl]="personCompanyIdControl" name="companyId">
                                <mat-option *ngFor="let company of selectPerson_companyId"
                                            [value]="company.value">{{company.value}}</mat-option>
                                <input class="input-company" matInput placeholder="Add Co ID" #input>
                                <button (click)="addNewID(input.value)" class="add-input-company">Add</button>
                            </mat-select>

                        </td>
                        <!--            <td class="merge-td">-->
                        <!--              <mat-select class="merge-select" (selectionChange)="constructMergedPerson('CompanyId', $event)"-->
                        <!--                          [formControl]="personCompanyIdControl" name="companyId">-->
                        <!--                <mat-option *ngFor="let companyId of selectPerson_companyId" [value]="companyId.value">-->
                        <!--                  {{companyId.value}}-->
                        <!--                </mat-option>-->
                        <!--              </mat-select>-->
                        <!--            </td>-->
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="sticky-button">
        <button *ngIf="companiesToMerge_length>1 || peopleToMerge_length>1" id="merge_proceed_btn" (click)="Proceed()"
                class="simple-button">Proceed
        </button>
        <button *ngIf="companiesToMerge_length>1 || peopleToMerge_length>1" id="merge_cancel_btn" class="cancel-button"
                mat-dialog-close>Cancel
        </button>
        <button id="merge_done_button" class="close-button" mat-dialog-close>Done</button>
    </div>

</div>
